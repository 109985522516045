import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = ({ text = 'An unexpected error occured' }) => {
    const navigate = useNavigate();

    return (
        <div className='min-h-[calc(100vh-489px)] sm:min-h-[calc(100vh-432px)] py-6 px-5vw flex items-center'>
            <div className='mx-auto flex flex-col gap-6 text-center'>
                <p className='font-medium text-red-500'>
                    <span className='material-symbols-outlined'>error</span>
                </p>
                <h3 className='font-semibold'>{text}</h3>
                <p>Please try again later.</p>

                <div className='flex items-center justify-center'>
                    <button
                        className='px-5 py-2 flex items-center gap-x-2 border rounded-lg'
                        onClick={() => navigate(-1)}
                    >
                        <span className='material-symbols-outlined'>
                            arrow_back
                        </span>

                        <span>Go back</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
