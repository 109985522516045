import React from 'react';
import SocialMediaLinks from './SocialMediaLinks';
import PageLinks from './PageLinks';
import FooterLogo from '../images/FooterLogo';

const Footer = () => {
    return (
        <footer className='bg-black'>
            <div className='max-w-4xl sm:flex sm:justify-between px-5vw lg:px-0 py-10 m-auto'>
                <div className='flex flex-col justify-between'>
                    <div className='w-32 mb-5 sm:mb-12'>
                        <FooterLogo />
                    </div>
                    <SocialMediaLinks />
                </div>
                <div className='basis-1/2 self-center'>
                    <PageLinks />
                </div>
            </div>
            <hr className='sm:mx-auto border-white' />
            <div className='max-w-4xl sm:flex sm:items-center sm:justify-between px-5vw lg:px-0 py-8 m-auto'>
                <span className=' text-white sm:text-center text-sm'>
                    © 2023 Skift Inc . All Rights Reserved.
                </span>
            </div>
        </footer>
    );
};

export default Footer;
