import React from 'react';
import ChartLoader from './ChartLoader';
import ChartError from './ChartError';
import Filter from './Filter';
import GraphLoader from './Graphs/GraphLoader';
import ChartFigure from './ChartFigure';
import './index.css';

const ChartContent = ({
    chartData,
    chartDataIsLoading,
    chartDataIsFetching,
    chartDataError,
    dateHolder,
    setDateHolder
}) => {
    if (chartDataIsLoading) return <ChartLoader />;
    if (chartDataError) return <ChartError />;

    return (
        <>
            <Filter
                data={chartData}
                disabled={chartDataIsFetching}
                dateHolder={dateHolder}
                setDateHolder={setDateHolder}
            />
            <div className='border grow overflow-hidden'>
                {chartDataIsFetching ? (
                    <GraphLoader />
                ) : (
                    <ChartFigure data={chartData} />
                )}
            </div>
        </>
    );
};

export default ChartContent;
