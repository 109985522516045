import React, { useState } from 'react';
import { useGetChartDataQuery } from '../../../../../redux/apiSlice';
import { useSelector } from 'react-redux';
import ChartContent from '../../../../ChartContent';

const SectionChart = ({ dashboardId, id: blockId, enabled_series = null }) => {
    const [dateHolder, setDateHolder] = useState({
        startDate: null,
        endDate: null
    });

    const isSubscriber = useSelector(
        state => state.reader?.user?.is_subscriber
    );

    const {
        data: chartData,
        error: chartDataError,
        isFetching: chartDataIsFetching,
        isLoading: chartDataIsLoading
    } = useGetChartDataQuery({
        dashboardId,
        blockId,
        dateHolder,
        enabled_series,
        isSubscriber
    });

    return (
        <div className='my-4'>
            <ChartContent
                chartData={chartData}
                chartDataIsLoading={chartDataIsLoading}
                chartDataIsFetching={chartDataIsFetching}
                chartDataError={chartDataError}
                dateHolder={dateHolder}
                setDateHolder={setDateHolder}
            />
        </div>
    );
};

export default SectionChart;
