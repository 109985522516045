import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initReader, setAuthenticating, setError } from '../redux/readerSlice';
import { useAuth0 } from '@auth0/auth0-react';
import apiSlice from '../redux/apiSlice';

const useAuth0LoginListener = () => {
    const { isAuthenticated: auth0Authenticated, getAccessTokenSilently } =
        useAuth0();
    const dispatch = useDispatch();
    const user = useSelector(state => state.reader?.user);

    useEffect(() => {
        const setLoginData = async () => {
            dispatch(setAuthenticating(true));
            const token = await getAccessTokenSilently();
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    credentials: 'include'
                }
            );
            const reader_info = await response.json();
            dispatch(initReader(reader_info));

            // reset all charts and dashboard to the new user information is used
            dispatch(apiSlice.util.resetApiState());
        };

        if (auth0Authenticated && !user?.signed_in) {
            setLoginData().catch(() => {
                dispatch(setError(true));
            });
        }
    }, [auth0Authenticated, getAccessTokenSilently, dispatch, user?.signed_in]);

    return;
};

export default useAuth0LoginListener;
