import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = ({
    currentSection,
    default_seo_settings
}) => (
    <ReactHelmet>
        <title>
            {currentSection?.seo_settings?.page_title}
        </title>
        <meta
            name='description'
            content={
                currentSection?.seo_settings?.meta_description ||
                default_seo_settings?.meta_description
            }
        />
        <meta
            property='og:description'
            content={
                currentSection?.seo_settings?.og_description ||
                default_seo_settings?.og_description
            }
        />
        <meta
            property='og:title'
            content={
                currentSection?.seo_settings?.og_title ||
                default_seo_settings?.og_title
            }
        />
        <meta
            property='og:image'
            content={
                currentSection?.seo_settings?.og_image ||
                default_seo_settings?.og_image
            }
        />
    </ReactHelmet>
);

export default Helmet;
