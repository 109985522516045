import React from 'react';
import { DateRangePicker, DateRange } from 'react-date-range';
import { correctUTCDate, createStaticRanges, subtractMonths } from '../../../utilities';

const Calender = ({ data, dateHolder, setDateHolder }) => {
    const selectionRange = {
        startDate: dateHolder?.startDate,
        endDate: dateHolder?.endDate,
        key: 'selection'
    };

    const handleSelect = ranges => {
        setDateHolder({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate
        });
    };

    const earliestStartDate = correctUTCDate(data?.earliest_start_date);
    const latestEndDate = correctUTCDate(data?.latest_end_date);

    const staticRanges = createStaticRanges([
        {
            label: '1 month',
            range: () => ({
                startDate: subtractMonths(new Date(), 1),
                endDate: latestEndDate
            })
        },
        {
            label: '3 month',
            range: () => ({
                startDate: subtractMonths(new Date(), 3),
                endDate: latestEndDate
            })
        },
        {
            label: 'YTD',
            range: () => ({
                startDate: new Date(new Date().getFullYear(), 0, 1),
                endDate: latestEndDate
            })
        },
        {
            label: '1 year',
            range: () => ({
                startDate: subtractMonths(new Date(), 12),
                endDate: latestEndDate
            })
        },
        {
            label: '3 years',
            range: () => ({
                startDate: subtractMonths(new Date(), 36),
                endDate: latestEndDate
            })
        },
        {
            label: 'All',
            range: () => ({
                startDate: earliestStartDate,
                endDate: latestEndDate
            })
        }
    ]);

    return (
        <>
            <div
                className='hidden lg:block'
                style={{ border: 'solid 1px #eff2f7' }}
            >
                <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showDateDisplay={false}
                    minDate={earliestStartDate}
                    maxDate={latestEndDate}
                    staticRanges={staticRanges}
                    inputRanges={[]}
                    months={1}
                />
            </div>
            <div
                className='block lg:hidden flex justify-center'
                style={{ border: 'solid 1px #eff2f7' }}
            >
                <DateRange
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showDateDisplay={false}
                    minDate={earliestStartDate}
                    maxDate={latestEndDate}
                />
            </div>
        </>
    );
};

export default Calender;
