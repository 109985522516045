import React from 'react';
import { findLabelFromKey } from '../../../utilities';
import { getContrastedColorFromHexCode } from '../../../utilities';

const CustomLegend = ({ data, activeSeries, setActiveSeries, payload }) => {
    const selectLine = e => {
        setActiveSeries({
            ...activeSeries,
            [e.dataKey]: !activeSeries[e.dataKey],
            hover: e.dataKey
        });
    };

    return (
        <ul
            className='flex justify-center items-center gap-2 mt-2'
            style={{
                flexWrap: 'wrap'
            }}
        >
            {payload.map(entry => {
                return (
                    <li
                        key={`${entry.dataKey}`}
                        onClick={() => selectLine(entry)}
                        className={`flex justify-center items-center cursor-pointer px-2 py-px gap-2 text-center rounded`}
                        style={{
                            backgroundColor: entry.inactive
                                ? '#f5f5f5'
                                : entry.color,
                            color: entry.inactive
                                ? 'black'
                                : getContrastedColorFromHexCode(entry.color)
                        }}
                    >
                        {entry.inactive ? (
                            <span className='material-symbols-outlined text-sm sm:text-base'>
                                check_box_outline_blank
                            </span>
                        ) : (
                            <span className='material-symbols-outlined text-sm sm:text-base'>
                                check_box
                            </span>
                        )}
                        {findLabelFromKey(data, entry.value)}
                    </li>
                );
            })}
        </ul>
    );
};

export default CustomLegend;
