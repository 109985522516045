import React from 'react';
import { findLabelFromKey, addCommasInNumbers } from '../../../utilities';

const CustomTooltip = ({ data, active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className='w-auto break-words flex-wrap custom-tooltip bg-white border border-slate-900 p-3 rounded'>
                <p className='text-sm mb-0'>{label.slice(0, 10)}</p>
                {payload.map(item => {
                    return (
                        <div
                            key={item.dataKey}
                            className='flex flex-row align-middle items-center'
                        >
                            <div
                                className='h-[0.8rem] w-[0.8rem] mr-1 flex align-middle items-center'
                                style={{
                                    backgroundColor: item.color
                                }}
                            ></div>
                            <p className='flex text-sm align-middle items-center mb-0'>
                                <b className='flex items-center align-middle'>
                                    {findLabelFromKey(data, item.dataKey)}
                                </b>
                                : {data?.y_axis_format === 'currency' && '$'}
                                {`${addCommasInNumbers(item.value)}`}
                                {data?.y_axis_format === 'percent' && '%'}
                            </p>
                        </div>
                    );
                })}
            </div>
        );
    }
    return;
};

export default CustomTooltip;
