import React from 'react';

const SectionRelatedPosts = ({ heading, posts, error }) => {
    if (error || !posts.length || !posts[0].id) {
        return null;
    }

    return (
        <div className='w-full overflow-auto [&>*:not(p)]:py-4'>
            <h2>{heading || 'Related Posts on Skift'}</h2>
            <ul>
                {(posts || []).map(post => (
                    <li key={post.id} className="mb-3">
                        <a href={post.link} className="hover:underline">
                            <div className='text-xs'>{new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>
                            <span className='text-lg' style={{ fontFamily: 'aria-text-g2, serif' }} dangerouslySetInnerHTML={{__html: post.title}} />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SectionRelatedPosts;
