import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'apiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl:
            process.env.REACT_APP_CHART_URL || process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: builder => ({
        getChartData: builder.query({
            query: ({ dashboardId, blockId, dateHolder, enabled_series }) => {
                let qs = [];
                if (dashboardId) {
                    qs.push(`dashboard_id=${dashboardId}`);
                }
                if (blockId) {
                    qs.push(`block_id=${blockId}`);
                }
                if (enabled_series) {
                    qs.push(`enabled_series=${enabled_series}`);
                }
                if (dateHolder?.startDate) {
                    qs.push(`start_date=${dateHolder.startDate.toISOString()}`);
                }
                if (dateHolder?.endDate) {
                    qs.push(`end_date=${dateHolder.endDate.toISOString()}`);
                }
                qs = qs.join('&');
                return `chart/?${qs}`;
            }
        }),
        getPoolData: builder.query({
            query: ({ id, limit, sort }) => {
                const qs = [];
                if (limit) {
                    qs.push(`limit=${limit}`);
                }
                if (sort) {
                    qs.push(`sort=${sort}`);
                }
                return `data-pools/${id}?${qs.join('&')}`;
            }
        }),
        getEmbedData: builder.query({
            query: ({ embedId, dateHolder }) => {
                const qs = [];
                if (dateHolder?.startDate) {
                    qs.push(`start_date=${dateHolder.startDate.toISOString()}`);
                }
                if (dateHolder?.endDate) {
                    qs.push(`end_date=${dateHolder.endDate.toISOString()}`);
                }
                return `embeds/${embedId}?${qs.join('&')}`;
            }
        }),
        getDashboardData: builder.query({
            query: slug => {
                return `dashboards/${slug}`;
            }
        })
    })
});

export const {
    useGetChartDataQuery,
    useGetPoolDataQuery,
    useGetEmbedDataQuery,
    useGetDashboardDataQuery
} = apiSlice;

export default apiSlice;
