import { isSameDay } from 'date-fns';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const embed = urlParams.get('embed');

export const subtractMonths = (date, months) => {
    date.setMonth(date.getMonth() - months);
    return date;
};

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    }
};

export const createStaticRanges = ranges => {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
};

export const renderCustomizedLabel = props => {
    const { x, y, width, value } = props;
    const additionalHeight = value > 0 ? -15 : 15;

    return (
        <text
            x={x + width / 2}
            y={y + additionalHeight}
            fill='black'
            textAnchor='middle'
            dominantBaseline='middle'
        >
            {value}
        </text>
    );
};

export const findLabelFromKey = (data, key) => {
    const matchedSeries = data?.series.find(series => series.data_key === key);
    return matchedSeries?.label;
};

export const addCommasInNumbers = num => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getContrastedColorFromHexCode = hex => {
    const rgb = convertHexToRGB(hex);

    return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186
        ? 'black'
        : 'white';
};

const convertHexToRGB = hex => {
    const hexCode = hex.charAt(0) === '#' ? hex.substr(1, 6) : hex;

    const hexR = parseInt(hexCode.substr(0, 2), 16);
    const hexG = parseInt(hexCode.substr(2, 2), 16);
    const hexB = parseInt(hexCode.substr(4, 2), 16);

    return {
        r: hexR,
        g: hexG,
        b: hexB
    };
};

export const defineGraphHeight = seriesLen => {
    if (embed) {
        return '100%';
    } else if (seriesLen > 8) {
        return 800;
    } else if (seriesLen > 6) {
        return 600;
    } else {
        return 400;
    }
};

export const numberToShorthand = (num, iterations = 0) => {
    if (!num || typeof num !== 'number') return num;

    const suffix = ['Thousand', 'Million', 'Billion', 'Trillion'];

    num = num / 1000;

    if (num >= 1000 && iterations < suffix.length - 1) {
        return numberToShorthand(num, iterations + 1);
    }

    return Math.floor(num) + ' ' + suffix[iterations];
};

export const stringGen = len => {
    let string = '';
    var charset = 'abcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < len; i++)
        string += charset.charAt(Math.floor(Math.random() * charset.length));
    return string;
};

export const correctUTCDate = date => {
    if ((date || '').indexOf('T00:00:00.000Z') !== -1) {
        // move time forward to timezone conversion from UTC to local time doesn't move the date to the previous day
        date = date.replace('T00:00:00.000Z', 'T12:00:00.000Z');
    }

    return new Date(date);
};
