import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { useState } from 'react';
import moment from 'moment';
import Calender from './Calender';
import { correctUTCDate } from '../../../utilities';

const Filter = ({ data, disabled, dateHolder, setDateHolder }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    if (!data?.has_date_filter) return;

    const startDate = correctUTCDate(data?.start_date);
    const endDate = correctUTCDate(data?.end_date);

    return (
        <div
            className={`relative pb-8 ${
                disabled && 'text-dark-gray cursor-not-allowed'
            }`}
        >
            <div className='flex flex-row items-center gap-3'>
                <div className='border h-[30px] w-[100px] sm:w-[150px] flex items-center justify-center'>
                    <span>{moment(startDate).format('l')}</span>
                </div>
                <span className='flex items-center'>
                    <span className='material-symbols-outlined'>
                        arrow_forward
                    </span>
                </span>
                <div className='border h-[30px] w-[100px] sm:w-[150px] flex items-center justify-center'>
                    <span>{moment(endDate).format('l')}</span>
                </div>
                <button
                    className={`border h-[30px] w-[30px] flex items-center justify-center rounded ${
                        isPopoverOpen && 'bg-dark-gray text-white'
                    }`}
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                    <span className='material-symbols-outlined pointer'>
                        {isPopoverOpen ? 'close' : 'date_range'}
                    </span>
                </button>
            </div>
            <div
                className={`${
                    isPopoverOpen ? 'block' : 'hidden'
                } absolute top-full left-[0] w-auto h-auto z-10 mt-3`}
                style={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                }}
                onClick={e => e.stopPropagation()}
            >
                <Calender
                    data={data}
                    dateHolder={dateHolder}
                    setDateHolder={setDateHolder}
                />
            </div>
            {isPopoverOpen && (
                <div
                    className='fixed top-0 left-0 right-0 bottom-0'
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                ></div>
            )}
        </div>
    );
};

export default Filter;
