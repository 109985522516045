import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "./apiSlice";
import filterReducer from "./filterSlice";
import readerReducer from "./readerSlice";

export const store = configureStore({
    reducer: {
        reader: readerReducer,
        filter: filterReducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(apiSlice.middleware)
});

setupListeners(store.dispatch);
