import React from 'react';
import { FOOTER_SOCIAL_MEDIA_SECTIONS } from '../../constants';

const SocialMediaLinks = () => (
    <div className='text-white flex my-5 [&>*:first-child]:ml-0'>
        {FOOTER_SOCIAL_MEDIA_SECTIONS.map((section, idx) => (
            <a
                key={idx}
                className='h-10 w-10 flex justify-center bg-white hover:bg-dark-gray text-black hover:text-white p-2.75 ml-4 rounded-full transition-colors ease-in cursor-pointer'
                href={section.link}
                target='_blank'
                rel='noreferrer'
            >
                {section.Component}
            </a>
        ))}
    </div>
);

export default SocialMediaLinks;
