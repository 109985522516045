import React from 'react';
import LineGraph from './Graphs/LineGraph';
import BarGraph from './Graphs/BarGraph';
import ChartError from './ChartError';

const ChartFigure = ({ data }) => {
    switch (data?.type) {
        case 'line':
            return <LineGraph data={data} />;
        case 'bar':
        case 'stacked_bar':
            return <BarGraph data={data} />;
        default:
            return <ChartError />;
    }
};

export default ChartFigure;
