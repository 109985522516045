import React from 'react';
import ContentBlock from './ContentBlock';
import Paywall from '../../Paywall';

const SectionContent = ({ currentSection, paywall_text, _id: dashboardId }) => {
    if (currentSection?.locked) return <Paywall paywallText={paywall_text} />;

    return currentSection?.content_blocks?.map((contentBlock, idx) => {
        return (
            <ContentBlock
                key={idx}
                contentBlock={contentBlock}
                dashboardId={dashboardId}
                paywallText={paywall_text}
            />
        );
    });
};
export default SectionContent;
