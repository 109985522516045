import React from 'react';
import HeaderLogInOut from './HeaderLogInOut';
import HeaderNavItems from './HeaderNavItems';

const MobileHeader = ({ setIsOpen, isOpen }) => (
    <section
        className={`absolute top-68 left-0 w-full wide:landscape:block sm:hidden px-5vw justify-start z-30  ${
            isOpen ? 'bg-white' : 'hidden wide:landscape:hidden'
        }`}
    >
        <nav className='relative min-h-[calc(100vh-70px)] flex flex-col gap-y-5 z-30'>
            <hr className='mt-5' />
            <HeaderNavItems closeNavBar={() => setIsOpen(false)} />
            <hr />
            <HeaderLogInOut />
        </nav>
    </section>
);

export default MobileHeader;
