import React, { useState } from 'react';
import SectionNavItems from './SectionNavItems';
import SectionBarUpArrow from '../../../images/SectionBarUpArrow';
import SectionBarDownArrow from '../../../images/SectionBarDownArrow';

const MobileSectionNav = ({ navItems, currentSectionIdx }) => {
    const [open, setIsOpen] = useState(false);

    return (
        <header className='block wide:landscape:block  sm:hidden w-full bg-gray z-10'>
            <div className='flex flex-col'>
                <div
                    className='h-40px px-5vw flex items-center cursor-pointer'
                    onClick={() => setIsOpen(!open)}
                >
                    <div className='w-2.5 mr-2.5'>
                        {open ? <SectionBarUpArrow /> : <SectionBarDownArrow />}
                    </div>
                    <span className='font-medium'>Table of Contents</span>
                </div>
                <nav
                    className={`${
                        open
                            ? 'section-nav-mobile'
                            : 'section-nav-mobile-closed'
                    } flex flex-col cursor-pointer bg-white`}
                >
                    <SectionNavItems
                        mobile={true}
                        setIsOpen={setIsOpen}
                        navItems={navItems}
                        currentSectionIdx={currentSectionIdx}
                    />
                </nav>
            </div>
        </header>
    );
};

export default MobileSectionNav;
