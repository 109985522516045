import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../redux/store';
import EmbedFetcher from './EmbedFetcher';

const Embed = ({ embedId }) => {
    return (
        <Provider store={store}>
            <div className='h-screen w-full flex flex-col'>
                <EmbedFetcher embedId={embedId} />
            </div>
        </Provider>
    );
};

export default Embed;
