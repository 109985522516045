import React from 'react';
import { NavLink } from 'react-router-dom';
import { HEADER_SECTIONS } from '../../constants';

const HeaderNavItems = ({
    closeNavBar = function () {
        return;
    }
}) => {
    return (
        <>
            {HEADER_SECTIONS.map((section, idx) => (
                <NavLink
                    key={idx}
                    to={section.route}
                    end
                    className='font-semibold cursor-pointer'
                    onClick={() => closeNavBar()}
                >
                    {section.name}
                </NavLink>
            ))}
        </>
    );
};

export default HeaderNavItems;
