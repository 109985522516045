import React from 'react';

const CustomYAxisTick = ({ x, y, payload, y_axis_format }) => {
    const tickFormatter = value => {
        switch (y_axis_format) {
            case 'percent':
                return value + '%';
            case 'currency':
                return '$' + value;
            default:
                return value;
        }
    };

    return (
        <g transform={`translate(${x}, ${y})`}>
            <text x={0} y={0} dy={5} textAnchor='end' className='text-sm'>
                {tickFormatter(payload.value)}
            </text>
            ;
        </g>
    );
};

export default CustomYAxisTick;
