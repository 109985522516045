import React from 'react';
import { Link } from 'react-router-dom';
import SectionNavSubItems from './SectionNavSubItems';

const SectionNavItems = ({
    navItems = [],
    currentSectionIdx = 0,
    mobile = false,
    setIsOpen = function () {
        return undefined;
    }
}) => (
    <ul className='sub-nav-desktop text-base cursor-default'>
        {navItems?.map((section, idx) => (
            <li
                key={`section-${idx}`}
                className={`${currentSectionIdx === idx && 'active'}`}
            >
                <Link
                    to={`/skift-travel-200/${section?.slug}`}
                    className={`h-40px sm:h-full sm:flex-1 flex items-center px-5vw sm:p-[10px] ${
                        currentSectionIdx === idx &&
                        'bg-dark-gray sm:bg-[#7cccf8] sm:font-bold text-white sm:text-black'
                    } ${section?.locked && 'pointer-events-none'}`}
                    onClick={() => setIsOpen(false)}
                >
                    <div className='w-full flex flex-row justify-between items-center'>
                        {section?.title}
                        {section?.locked && (
                            <span className='material-symbols-outlined text-[16px]'>
                                lock
                            </span>
                        )}
                    </div>
                </Link>
                <SectionNavSubItems
                    mobile={mobile}
                    subNavItems={section?.sub_nav_items}
                />
            </li>
        ))}
    </ul>
);
export default SectionNavItems;
