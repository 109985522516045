import React from 'react';
import SectionNavItems from './SectionNavItems';

const DesktopSectionNav = ({ navItems, currentSectionIdx }) => (
    <header className='hidden wide:landscape:hidden sm:sticky sm:top-[110px] w-auto sm:block'>
        <nav className={`flex flex-col text-base cursor-pointer pl-5vw`}>
            <SectionNavItems
                navItems={navItems}
                currentSectionIdx={currentSectionIdx}
            />
        </nav>
    </header>
);

export default DesktopSectionNav;
