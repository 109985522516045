import React from 'react';

const SectionNavSubItems = ({ subNavItems = [], mobile = false }) => {
    if (mobile) return;

    return (
        <ul>
            {subNavItems?.map((subNavItem, idx) => (
                <li key={`chart-${idx}`}>
                    <a
                        href={`#${subNavItem.anchor}`}
                        className='sm:flex-1 flex items-center p-[10px] bg-[#bae6fd]'
                    >
                        {subNavItem.title}
                    </a>
                </li>
            ))}
        </ul>
    );
};
export default SectionNavSubItems;
