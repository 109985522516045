import React, { useState } from 'react';
import { useGetEmbedDataQuery } from '../../redux/apiSlice';
import ChartContent from '../ChartContent';

const EmbedFetcher = ({ embedId }) => {
    const [dateHolder, setDateHolder] = useState({
        startDate: null,
        endDate: null
    });

    const {
        data,
        error,
        isFetching,
        isLoading
    } = useGetEmbedDataQuery({
        embedId,
        dateHolder
    }, { skip: !!window.embed });

    return (
        <ChartContent
            chartData={window.embed || data}
            chartDataError={error}
            chartDataIsFetching={isFetching}
            chartDataIsLoading={isLoading}
            dateHolder={dateHolder}
            setDateHolder={setDateHolder}
        />
    );
};

export default EmbedFetcher;
