import React from "react";

const FooterLogo = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 905.8 489.3">
        <g>
            <path
                d="M272.3,8.6h72v168l67.9-77.3h84l-80.5,85.8l89.8,143H421l-55-93.4l-21.9,23.2v70.2h-72V8.6H272.3z"
                fill="#ffffff"
            />
            <path
                d="M625.4,145.8h-29.5V99.3h29.5V82.8c0-22.9,6.3-40.7,18.8-53.2c9.8-9.5,23.5-15.7,41.1-18.5s36.2-3.2,55.9-1.1v51.4
		c-6.9-0.3-13-0.2-18.3,0.2c-5.4,0.4-9.8,1.6-13.4,3.4c-3.6,1.8-6.3,4.5-8.3,8.3c-1.9,3.7-2.9,8.7-2.9,15v11.2h42.9V146h-42.9v182.4
		h-72.9L625.4,145.8L625.4,145.8z"
                fill="#ffffff"
            />
            <g>
                <path d="M514.3,99.3h72.9v228.9h-72.9V99.3z" fill="#ffffff" />
                <circle cx="550.7" cy="38.1" r="38.1" fill="#ffffff" />
            </g>
            <g>
                <g>
                    <polygon
                        points="851.3,145.8 892.8,145.8 892.8,99.3 851.3,99.3 851.3,26.9 780.2,26.9 780.2,99.3 749.8,99.3 749.8,145.8 
				780.2,145.8 780.2,328.2 851.3,328.2 816.9,237"
                        fill="#ffffff"
                    />
                </g>
                <circle cx="878.4" cy="237" r="27.4" fill="#ffffff" />
            </g>
            <g>
                <path
                    d="M254.3,189.2c-6.3-11.3-14.6-20.7-25-28.2c-10.4-7.4-22.4-13.4-36-17.9s-27.8-8.5-42.7-12.1c-11.9-2.7-22.4-5.1-31.5-7.4
			c-9.1-2.2-16.6-4.7-22.6-7.4c-6-2.7-10.5-5.9-13.6-9.6s-4.7-8.4-4.7-14.1c0-8.9,4.2-16.2,12.5-21.7s19.5-8.3,33.5-8.3
			c14.9,0,26.8,3.3,35.8,9.8c8.9,6.6,14.2,16.1,15.6,28.6h77.3c-0.9-16.7-4.7-31.2-11.4-43.6c-6.7-12.4-15.6-22.6-26.8-30.6
			c-11.2-8-24.3-14.1-39.3-18.1c-15.1-4-31.2-6-48.5-6c-17.9,0-34.6,1.9-50.1,5.8s-28.9,9.8-40.2,17.9c-11.3,8-20.3,18.3-26.8,30.6
			c-6.6,12.4-9.8,27-9.8,44c0,16.7,3.4,30.5,10.1,41.6c6.7,11,15.4,20.1,26.1,27.3c10.7,7.2,22.9,12.8,36.4,17
			c13.6,4.2,27.3,7.9,41.3,11.2c11,2.7,20.9,5.1,29.5,7.4c8.6,2.2,15.9,4.8,21.9,7.8s10.5,6.6,13.6,10.7c3.1,4.2,4.7,9.4,4.7,15.6
			c0,6-1.5,11.1-4.5,15.4c-3,4.3-6.9,7.8-11.6,10.5c-4.8,2.7-10.2,4.6-16.3,5.8s-12.4,1.8-19,1.8c-19,0-33.8-3.6-44.2-10.7
			l-23.7,63.1c3.4,1.1,6.9,2.2,10.5,3.2c17.4,4.6,36.7,6.9,57.9,6.9c18.5,0,35.7-2.3,51.6-6.9c15.9-4.6,29.8-11.3,41.6-20.1
			c11.8-8.8,21-19.7,27.7-32.6c6.7-13,10.1-27.8,10.1-44.5C263.7,214.6,260.5,200.5,254.3,189.2z"
                    fill="#ffffff"
                />
            </g>
        </g>
        <g>
            <path
                d="M123.5,487.8l-21.8-31.3c-1.4,0.2-2.7,0.2-4.1,0.2H73.5v31.1H53.7V380.9h44c28.1,0,45.5,14.3,45.5,38
		c0,16.2-8.2,28.1-22.7,33.7l24.6,35.1h-21.6V487.8z M96.6,397.7H73.4v42.6h23.2c17.4,0,26.4-7.9,26.4-21.4
		C123,405.5,114,397.7,96.6,397.7z"
                fill="#ffffff"
            />
            <path
                d="M245.3,471.1v16.6h-80.1V380.9h78v16.7H185v27.8h51.6v16.3H185v29.5L245.3,471.1L245.3,471.1z"
                fill="#ffffff"
            />
            <path
                d="M258.4,476.5l6.9-15.4c8.5,6.9,22.1,11.8,35.3,11.8c16.6,0,23.7-6,23.7-13.9c0-23-63.5-7.9-63.5-47.6
		c0-17.2,13.7-31.9,43-31.9c12.8,0,26.3,3.4,35.6,9.5l-6.3,15.4c-9.6-5.6-20.1-8.4-29.5-8.4c-16.5,0-23.2,6.4-23.2,14.5
		c0,22.7,63.3,7.9,63.3,47.2c0,17.1-13.9,31.7-43.2,31.7C283.9,489.3,267.2,484.1,258.4,476.5z"
                fill="#ffffff"
            />
            <path
                d="M443.5,471.1v16.6h-80.1V380.9h78v16.7h-58.2v27.8h51.6v16.3h-51.6v29.5L443.5,471.1L443.5,471.1z"
                fill="#ffffff"
            />
            <path
                d="M534.9,463h-53.4L471,487.7h-20.5l48.1-106.8h19.5l48.2,106.8h-20.8L534.9,463z M528.4,447.5l-20.1-46.7l-20,46.7H528.4z"
                fill="#ffffff"
            />
            <path
                d="M649.9,487.8l-21.8-31.3c-1.4,0.2-2.7,0.2-4.1,0.2h-24.1v31.1h-19.8V380.9h44c28.1,0,45.5,14.3,45.5,38
		c0,16.2-8.2,28.1-22.7,33.7l24.6,35.1h-21.6V487.8z M623,397.7h-23.2v42.6H623c17.4,0,26.4-7.9,26.4-21.4
		C649.4,405.5,640.4,397.7,623,397.7z"
                fill="#ffffff"
            />
            <path
                d="M683.9,434.3c0-31.9,24.4-54.9,57.2-54.9c17.4,0,32.4,6.3,42.3,17.9l-12.8,12.1c-7.8-8.4-17.4-12.5-28.5-12.5
		c-22.1,0-38.2,15.6-38.2,37.5c0,22,16,37.5,38.2,37.5c11.1,0,20.8-4.1,28.5-12.7l12.8,12.2c-9.9,11.6-24.9,17.9-42.4,17.9
		C708.3,489.3,683.9,466.2,683.9,434.3z"
                fill="#ffffff"
            />
            <path
                d="M897.4,380.9v106.8h-19.8v-45.8h-55.3v45.8h-19.8V380.9h19.8V425h55.3v-44.1H897.4z"
                fill="#ffffff"
            />
        </g>
    </svg>
);

export default FooterLogo;
