import CustomYAxisTick from './CustomYAxisTick';
import { XAxis, YAxis } from 'recharts';

const Axis = data => {
    const insertRightYAxis = () => {
        if (data?.y_axis_label_right) {
            return (
                <YAxis
                    key={data?.y_axis_label_right}
                    axisLine={false}
                    label={{
                        value: `${data?.y_axis_label_right}`,
                        position: 'insideRight',
                        angle: -90,
                        style: { textAnchor: 'middle', fontSize: '0.9rem' }
                    }}
                    yAxisId='right'
                    orientation='right'
                    domain={['auto', 'auto']}
                />
            );
        } else {
            return;
        }
    };

    return [
        <XAxis
            key={data?.x_axis_data_key}
            dataKey={data?.x_axis_data_key}
            style={{
                fontSize: '0.9rem'
            }}
        />,
        <YAxis
            key={data?.y_axis_label}
            tick={<CustomYAxisTick y_axis_format={data?.y_axis_format} />}
            axisLine={false}
            label={{
                value: `${data?.y_axis_label}`,
                position: 'insideLeft',
                angle: -90,
                dx: -10,
                style: {
                    textAnchor: 'middle',
                    fontSize: '0.9rem'
                }
            }}
            yAxisId='left'
            domain={['auto', 'auto']}
        />,
        insertRightYAxis()
    ];
};

export default Axis;
