import React from 'react';
import { useSelector } from 'react-redux';

const Paywall = ({ paywallText }) => {
    const signed_in = useSelector(state => state?.reader?.user?.signed_in);

    return (
        <div className='min-h-[calc(100vh-489px)] sm:min-h-[calc(100vh-432px)] py-6 px-5vw flex items-center'>
            <div className='max-w-[500px] mx-auto flex flex-col gap-6 text-center'>
                <span className='material-symbols-outlined text-2xl sm:text-3xl'>
                    lock
                </span>
                <h3 className='font-semibold'>{paywallText?.heading}</h3>
                <p className='mb-0'>{paywallText?.message}</p>

                <div className='flex items-center justify-center'>
                    <a
                        className='px-[30px] py-[13px] bg-black text-white'
                        href={paywallText?.button_url}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {paywallText?.button_text}
                    </a>
                </div>
                {!signed_in && (
                    <p className='text-sm text-center whitespace-nowrap mb-0'>
                        Already a subscriber?{' '}
                        <a href={`${process.env.REACT_APP_LOGIN_LINK}?redirect=${encodeURIComponent(window.location.href)}`} className='underline'>
                            Login
                        </a>
                    </p>
                )}
            </div>
        </div>
    );
};

export default Paywall;
