import React from 'react';
import StatBadge from './StatBadge';
import SectionHeading from './SectionHeading';
import SectionHtml from './SectionHtml';
import SectionChart from './SectionChart';
import SectionCommentary from './SectionCommentary';
import SectionDataTable from './SectionDataTable';
import SectionRule from './SectionRule';
import ChartPlaceholder from '../../../../ChartContent/ChartPlaceHolder';
import SectionRelatedPosts from './SectionRelatedPosts';

const ContentBlock = ({ contentBlock, dashboardId, paywallText }) => {
    switch (contentBlock.type) {
        case 'stat_badge':
            return <StatBadge {...contentBlock} />;
        case 'heading':
            return <SectionHeading {...contentBlock} />;
        case 'rte':
            return <SectionHtml {...contentBlock} />;
        case 'chart':
            if (contentBlock?.obscured) return <ChartPlaceholder paywallText={paywallText} />
            
            return (
                <SectionChart
                    {...contentBlock}
                    dashboardId={dashboardId}
                />
            );
        case 'commentary':
            return <SectionCommentary {...contentBlock} />;
        case 'data_table':
            return <SectionDataTable {...contentBlock} />;
        case 'rule':
            return <SectionRule />;
        case 'related_posts':
            return <SectionRelatedPosts {...contentBlock} />;
        default:
            return;
    }
};

export default ContentBlock;
