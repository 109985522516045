import React, { useState } from 'react';
import useScrollWithShadow from '../../hooks/useScrollWithShadow';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import './index.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    useScrollWithShadow();

    return (
        <header className='w-full fixed top-0 bg-white z-50'>
            <div className='max-w-screen-3xl px-5vw m-auto'>
                <DesktopHeader setIsOpen={setIsOpen} isOpen={isOpen} />
                <MobileHeader setIsOpen={setIsOpen} isOpen={isOpen} />
            </div>
        </header>
    );
};

export default Header;
