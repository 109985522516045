import React from 'react';

const ChartLoader = () => (
    <div className='flex items-center justify-center  min-h-[400px] sm:min-h-[400px] min-w-screen py-6'>
        <div className='flex space-x-2 animate-pulse'>
            <div className='w-3 h-3 bg-black rounded-full'></div>
            <div className='w-3 h-3 bg-black rounded-full'></div>
            <div className='w-3 h-3 bg-black rounded-full'></div>
        </div>
    </div>
);

export default ChartLoader;
