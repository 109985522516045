import React from 'react';

const SectionDataTable = ({ columns = [], data = [] }) => (
    <div className='w-full overflow-auto my-4 landscape:wide:my-4 sm:my-10'>
        <table>
            <thead>
                <tr>
                    {columns.map((col, i) => (
                        <th key={i}>{col.label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, i) => (
                    <tr key={i}>
                        {columns.map((col, j) => (
                            <td key={j}>{row[col.property]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default SectionDataTable;
