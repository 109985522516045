import FaceBookIcon from './components/images/FaceBookIcon';
import TwitterIcon from './components/images/TwitterIcon';
import LinkedInIcon from './components/images/LinkedInIcon';
import InstagramIcon from './components/images/InstagramIcon';
import YouTubeIcon from './components/images/YouTubeIcon';
import MailIcon from './components/images/MailIcon';

// header
export const HEADER_SECTIONS = [
    {
        name: 'Skift Travel 200',
        route: 'skift-travel-200/'
    },
    {
        name: 'Hotel Tech Benchmark',
        route: 'https://research.skift.com/hotel-tech-benchmark/'
    },
    {
        name: 'Travel Health Index',
        route: 'https://research.skift.com/recovery/'
    }
];

// footer
export const FOOTER_SOCIAL_MEDIA_SECTIONS = [
    {
        name: 'Facebook',
        link: 'https://www.facebook.com/Skiftnews/',
        Component: <FaceBookIcon />
    },
    {
        name: 'Twitter',
        link: 'https://twitter.com/skift',
        Component: <TwitterIcon />
    },
    {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/company/skift',
        Component: <LinkedInIcon />
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/skiftnews/',
        Component: <InstagramIcon />
    },
    {
        name: 'YouTube',
        link: 'https://www.instagram.com/skiftnews/',
        Component: <YouTubeIcon />
    },
    {
        name: 'Mail',
        link: 'https://skift.com/newsletters/',
        Component: <MailIcon />
    }
];

export const FOOTER_PAGE_SECTIONS = [
    {
        name: 'About Skift',
        link: 'https://skift.com/about/'
    },
    {
        name: 'Advertise With Us',
        link: 'https://skift.com/skiftx-creative-studio/'
    },
    {
        name: 'Privacy Policy',
        link: 'https://skift.com/privacy/'
    },
    {
        name: 'Help Center',
        link: 'https://info.skift.com/knowledge/'
    },
    {
        name: 'Terms Of Use',
        link: 'https://skift.com/terms/'
    }
];
