import React from 'react';

const HeaderToggle = ({ setIsOpen, isOpen }) => (
    <label
        className='relative w-30px h-25px block wide:landscape:block sm:hidden cursor-pointer'
        onClick={() => setIsOpen(!isOpen)}
    >
        <div
            className={`absolute origin-left top-0 left-0 h-3px w-30px bg-black transition-300ms ${
                isOpen && 'hamburger-top-line-animation'
            }`}
        ></div>

        <div className='absolute top-2/4 w-30px flex justify-between items-center -mt-1px'>
            <div
                className={`h-3px w-7/12 bg-black transition-300ms ${
                    isOpen && 'hamburger-middle-line-animation'
                }`}
            ></div>

            <div
                className={`h-3px w-1 rounded-full bg-black transition-300ms ${
                    isOpen && 'hamburger-middle-dot-animation'
                }`}
            ></div>
        </div>
        <div
            className={`absolute origin-left bottom-0 left-0 h-3px w-30px bg-black transition-300ms ${
                isOpen && 'hamburger-bottom-line-animation'
            }`}
        ></div>
    </label>
);

export default HeaderToggle;
