import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import SectionNav from './SectionNav';
import SectionContent from './SectionContent';
import SectionBackAndForwardNavigator from './SectionBackAndForwardNavigator';
import Helmet from './Helmet';

const Section = ({ dashboardData }) => {
    const { section: sectionRoute } = useParams();
    const sections = useMemo(
        () => dashboardData?.sections || [],
        [dashboardData?.sections]
    );
    const currentSectionIdx = useMemo(() => {
        const sectionIdx = sections.findIndex(
            section => section?.slug === sectionRoute
        );
        return sectionIdx > -1 ? sectionIdx : 0;
    }, [sections, sectionRoute]);
    const currentSection = sections[currentSectionIdx];

    return (
        <div className='max-w-screen-3xl sm:py-[60px] wide:landscape:py-[0px] m-auto sm:flex wide:landscape:inline-block sm:justify-center'>
            <Helmet currentSection={currentSection} {...dashboardData} />
            {dashboardData?.nav && (
                <div className='basis-3/12'>
                    <SectionNav
                        navItems={dashboardData?.nav}
                        currentSectionIdx={currentSectionIdx}
                    />
                </div>
            )}
            <div className='section-content min-h-auto overflow-scroll basis-9/12 px-5vw flex flex-col'>
                <SectionContent
                    currentSection={currentSection}
                    {...dashboardData}
                />
                <SectionBackAndForwardNavigator
                    navItems={dashboardData?.nav}
                    currentSectionIdx={currentSectionIdx}
                    {...dashboardData}
                />
            </div>
        </div>
    );
};

export default Section;
