import React from 'react';

const StatBadgePlaceholder = () => (
    <div className='absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2'>
        <div className='w-full flex flex-col justify-center items-center'>
            <span className='material-symbols-outlined mb-1'>lock</span>
            <a
                className='text-base sm:text-lg md:text-xl whitespace-nowrap'
                href='https://research.skift.com/subscribe/'
                target='_blank'
                rel='noreferrer'
            >
                Get Access
            </a>
        </div>
    </div>
);

export default StatBadgePlaceholder;
