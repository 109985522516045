import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    index_performance: { start_date: "", end_date: "" },
    index_performance_vs_global_stocks: { start_date: "", end_date: "" }
};

export const filterSlice = createSlice({
    name: "filter",
    initialState: initialState,
    reducers: {
        setFilter: (state, action) => {
            state = { ...state, ...action.payload };
            return state;
        },
        resetFilters: state => {
            state = initialState;
            return state;
        }
    }
});

export const { setFilter, resetFilters } = filterSlice.actions;

export default filterSlice.reducer;
