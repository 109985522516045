import { useEffect } from "react";
import useGetReader from "../hooks/useGetReader";
import useAuth0LoginListener from "../hooks/useAuth0LoginListener";

const InitReader = () => {
    const { initReader } = useGetReader();

    useAuth0LoginListener();

    useEffect(() => {
        initReader();
    }, [initReader]);

    return null;
};

export default InitReader;
