import React from 'react';

const GraphLoader = () => {
    return (
        <div className='content-wrapper flex items-center justify-center h-[400px]'>
            <div className='flex space-x-2 animate-pulse'>
                <div className='w-3 h-3 bg-black rounded-full'></div>
                <div className='w-3 h-3 bg-black rounded-full'></div>
                <div className='w-3 h-3 bg-black rounded-full'></div>
            </div>
        </div>
    );
};

export default GraphLoader;
