import React, { useState } from 'react';
import {
    BarChart,
    Bar,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine
} from 'recharts';
import CustomTooltip from './CustomToolTip';
import CustomLegend from './CustomLegend';
import Axis from './Axis';
import { defineGraphHeight } from '../../../utilities';

const BarGraph = ({ data }) => {
    const [activeSeries, setActiveSeries] = useState(
        data?.series.reduce(
            (series, { dataKey }) => {
                series[dataKey] = false;
                return series;
            },
            { hover: null }
        )
    );

    const insertBars = () => {
        return data?.series.map(series => {
            return (
                <Bar
                    key={series._id}
                    hide={activeSeries[series.data_key] === true}
                    dataKey={series.data_key}
                    fill={series.color}
                    barSize={data?.type === 'stacked_bar' ? null : 50}
                    stackId={
                        data?.type === 'stacked_bar'
                            ? series?.x_axis_property
                            : null
                    }
                    yAxisId={series?.y_axis_position || 'left'}
                ></Bar>
            );
        });
    };

    return (
        <ResponsiveContainer
            className='watermark'
            width='100%'
            height={defineGraphHeight(data?.series?.length)}
        >
            <BarChart
                data={data?.data}
                margin={{
                    top: 30,
                    right: 40,
                    bottom: 20,
                    left: 30
                }}
                stackOffset='sign'
            >
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                {Axis(data)}
                <Tooltip content={<CustomTooltip data={data} />} />
                {data?.series.length > 1 && (
                    <Legend
                        content={
                            <CustomLegend
                                data={data}
                                activeSeries={activeSeries}
                                setActiveSeries={setActiveSeries}
                            />
                        }
                    />
                )}
                {insertBars()}
                {data?.type === 'stacked_bar' && (
                    <ReferenceLine y={0} yAxisId='left' />
                )}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarGraph;
