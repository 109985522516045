import React from 'react';
import { useSelector } from 'react-redux';


import HeaderLoader from './HeaderLoader';

const HeaderLogInOut = () => {
    const is_authenticating = useSelector(
        state => state.reader?.is_authenticating
    );
    const signed_in = useSelector(state => state?.reader?.user?.signed_in);

    if (is_authenticating) return <HeaderLoader />;

    return (
        <div className='flex items-center'>
            {signed_in ? (
                <>
                    <span className='font-semibold material-symbols-outlined'>
                        person
                    </span>
                    <a href={`${process.env.REACT_APP_LOGOUT_LINK}?redirect=${encodeURIComponent(window.location.href)}`} className='text-base sm:text-sm'>
                        Logout
                    </a>
                    <span className='text-base sm:text-sm px-1'>|</span>
                    <a
                        className='text-base sm:text-sm'
                        href='https://my.skift.com'
                    >
                        Account
                    </a>
                </>
            ) : (
                <>
                    <span className='font-semibold material-symbols-outlined'>
                        person
                    </span>
                    <a href={`${process.env.REACT_APP_LOGIN_LINK}?redirect=${encodeURIComponent(window.location.href)}`} className='text-base sm:text-sm'>
                        Login
                    </a>
                </>
            )}
        </div>
    );
};

export default HeaderLogInOut;
