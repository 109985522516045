import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import InitReader from './InitReader';

const App = () => (
    <Provider store={store}>
        <InitReader />
        <Router>
            <ScrollToTop />
            <div className='flex flex-col justify-between'>
                <Header />
                <div className='mt-70px'>
                    <Main />
                </div>
                <Footer />
            </div>
        </Router>
    </Provider>
);

export default App;
