import React from 'react';
import { FOOTER_PAGE_SECTIONS } from '../../constants';

const PageLinks = () => (
    <div className='flex flex-wrap gap-y-2 sm:ml-14 text-white text-sm'>
        {FOOTER_PAGE_SECTIONS.map((section, idx) => (
            <div key={idx} className='basis-1/2'>
                <a
                    className='hover:text-yellow hover:underline cursor-pointer'
                    href={section.link}
                    target='_blank'
                    rel='noreferrer'
                >
                    {section.name}
                </a>
            </div>
        ))}
    </div>
);

export default PageLinks;
