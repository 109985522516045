import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollWithShadow = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        // reset the box shadow
        const header = document.querySelector('header');
        // header.style.boxShadow = "none";

        const handleScroll = () => {
            if (window.scrollY !== 0) {
                header.style.boxShadow = '0 10px 20px 0 rgba(0,0,0,.1)';
            } else {
                header.style.boxShadow = 'none';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pathname]);
};

export default useScrollWithShadow;
