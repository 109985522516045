import React from 'react';

const SectionHeading = ({ heading_level, content, anchor = '' }) => {
    switch (heading_level) {
        case 1:
            return (
                <h1
                    id={anchor}
                    className='py-4 sm:pt-0 sm:pb-8 wide:landscape:py-8'
                >
                    {content}
                </h1>
            );
        case 2:
            return (
                <h2
                    id={anchor}
                    className='mt-0 sm:-mt-[45px] wide:landscape:mt-4 pt-4 mb-4 sm:mb-10 wide:landscape:mb-4 sm:pt-[85px] wide:landscape:pt-0'
                >
                    {content}
                </h2>
            );
        case 3:
            return (
                <h3 id={anchor} className='my-4 sm:mb-8 wide:landscape:mb-4'>
                    {content}
                </h3>
            );
        case 4:
            return (
                <h4 id={anchor} className='my-4 sm:mb-8 wide:landscape:mb-4'>
                    {content}
                </h4>
            );
        case 5:
            return (
                <h5 id={anchor} className='my-4 sm:mb-8 wide:landscape:mb-4'>
                    {content}
                </h5>
            );
        case 6:
            return (
                <h6 id={anchor} className='my-4 sm:mb-8 wide:landscape:mb-4'>
                    {content}
                </h6>
            );
        default:
            return;
    }
};

export default SectionHeading;
