import React from 'react';
import { Link } from 'react-router-dom';

const SectionBackAndForwardNavigator = ({
    navItems,
    currentSectionIdx,
    slug: dashboardSlug
}) => {
    if (!navItems) return

    const insertPrevSectionLink = (idx, prevIdx = idx - 1) => {
        if (idx <= 0) {
            return <div></div>;
        }

        if (navItems[prevIdx]?.locked) {
            return insertPrevSectionLink(idx - 1);
        }

        return (
            <Link
                className='section-button-wrapper mr-4'
                to={`/${dashboardSlug}/${navItems[prevIdx].slug}`}
            >
                <span className='material-symbols-outlined'>arrow_back</span>
                {navItems[prevIdx].title}
            </Link>
        );
    };

    const insertNextSectionLink = (idx, nextIdx = idx + 1) => {
        if (idx >= navItems.length - 1) {
            return <div></div>;
        }

        if (navItems[nextIdx]?.locked) {
            return insertNextSectionLink(idx + 1);
        }

        return (
            <Link
                className='section-button-wrapper ml-4'
                to={`/${dashboardSlug}/${navItems[nextIdx].slug}`}
            >
                <span className='material-symbols-outlined'>arrow_forward</span>
                {navItems[nextIdx].title}
            </Link>
        );
    };

    return (
        <div className='flex justify-between py-8 wide:landscape:py-8 sm:pb-0'>
            {insertPrevSectionLink(currentSectionIdx)}
            {insertNextSectionLink(currentSectionIdx)}
        </div>
    );
};

export default SectionBackAndForwardNavigator;
