import React from 'react';

const ChartError = () => (
    <div className='flex items-center min-h-[400px] py-6'>
        <div className='flex flex-col mx-auto text-center gap-6'>
            <p className='font-medium text-red-500'>
                <span className='material-symbols-outlined'>error</span>
            </p>
            <h3 className='font-semibold'>Couldn't load the chart</h3>
            <p>Please try again later.</p>
        </div>
    </div>
);

export default ChartError;
