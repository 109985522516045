import React from 'react';
import { useSelector } from 'react-redux';
import BlurGraphImage from '../images/BlurGraphImage.png';

const ChartPlaceholder = ({ paywallText }) => {
    const signed_in = useSelector(state => state?.reader?.user?.signed_in);

    return (
        <div className='my-4 w-full relative'>
            <img src={BlurGraphImage} alt='blurred-graph' />
            <div className='absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-10'>
                <div className='w-full flex flex-col justify-center items-center gap-3'>
                    <span className='material-symbols-outlined'>lock</span>
                    <h4 className='text-base sm:text-lg md:text-xl whitespace-nowrap'>
                        Get Access To This Chart When You Subscribe
                    </h4>
                    <a
                        className='px-30px py-13px bg-black text-sm sm:text-base text-white'
                        href={paywallText?.button_url}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {paywallText?.button_text}
                    </a>
                    {!signed_in && (
                        <p className='text-sm text-center whitespace-nowrap mb-0'>
                            Already a subscriber?{' '}
                            <a href={`${process.env.REACT_APP_LOGIN_LINK}?redirect=${encodeURIComponent(window.location.href)}`} className='underline'>
                                Login
                            </a>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChartPlaceholder;
