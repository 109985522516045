import React from 'react';
import DesktopSectionNav from './DesktopSectionNav';
import MobileSectionNav from './MobileSectionNav';
import './index.css';

const SectionNav = ({ navItems, currentSectionIdx }) => (
    <>
        <DesktopSectionNav
            navItems={navItems}
            currentSectionIdx={currentSectionIdx}
        />
        <MobileSectionNav
            navItems={navItems}
            currentSectionIdx={currentSectionIdx}
        />
    </>
);

export default SectionNav;
