import React, { useState } from 'react';
import {
    LineChart,
    Line,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from 'recharts';
import CustomTooltip from './CustomToolTip';
import CustomLegend from './CustomLegend';
import Axis from './Axis';
import { defineGraphHeight } from '../../../utilities';

const LineGraph = ({ data }) => {
    const [activeSeries, setActiveSeries] = useState(
        data?.series.reduce(
            (series, { dataKey }) => {
                series[dataKey] = false;
                return series;
            },
            { hover: null }
        )
    );

    const insertLines = () => {
        return data?.series.map(series => {
            return (
                <Line
                    key={series._id}
                    hide={activeSeries[series.data_key] === true}
                    dataKey={series.data_key}
                    stroke={series.color}
                    dot={false}
                    strokeWidth={2}
                    yAxisId={series?.y_axis_position || 'left'}
                />
            );
        });
    };

    return (
        <ResponsiveContainer
            className='watermark'
            width='100%'
            height={defineGraphHeight(data?.series?.length)}
        >
            <LineChart
                data={data?.data}
                margin={{
                    top: 30,
                    right: 40,
                    bottom: 20,
                    left: 30
                }}
            >
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                {Axis(data)}
                <Tooltip content={<CustomTooltip data={data} />} />
                {data?.series.length > 1 && (
                    <Legend
                        content={
                            <CustomLegend
                                data={data}
                                activeSeries={activeSeries}
                                setActiveSeries={setActiveSeries}
                            />
                        }
                    />
                )}
                {insertLines()}
            </LineChart>
        </ResponsiveContainer>
    );
};
export default LineGraph;
