import React from 'react';
import parse from 'html-react-parser';
import { stringGen } from '../../../../../utilities';

const SectionCommentary = ({ content, byline, comment_length, obscured }) => (
    <div className='rounded-md bg-[#bae6fd] text-black py-5 px-6 my-4 flex'>
        <div className='mr-4'>
            <span className='font-bold text-[#418CD0] text-4xl material-symbols-outlined'>
                quickreply
            </span>
        </div>
        <div className='flex flex-col gap-2'>
            <h4 className='font-bold text-xl'>Analyst Commentary</h4>
            <div className={`${obscured && 'break-all blur'}`}>
                {obscured ? stringGen(comment_length) : parse(content)}
            </div>
            - {byline}
        </div>
    </div>
);

export default SectionCommentary;
