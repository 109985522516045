import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDashboardDataQuery } from '../../redux/apiSlice';
import Section from './Section';
import Paywall from './Paywall';
import PageLoader from '../PageLoader';
import ErrorPage from '../ErrorPage';

const Dashboard = () => {
    const { slug } = useParams();
    const {
        data: fetchedDashboard,
        isFetching,
        error
    } = useGetDashboardDataQuery(slug, { skip: window.dashboard });

    const dashboard = window.dashboard || fetchedDashboard;

    if (isFetching) return <PageLoader />;
    if (error) return <ErrorPage />;

    if (dashboard?.show_paywall)
        return <Paywall paywallText={dashboard?.paywall_text} />;

    return <Section dashboardData={dashboard} />;
};

export default Dashboard;
