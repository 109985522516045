import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useHubspotTracking = () => {
    const [lastLocation, setLastLocation] = useState();
    const [identitySent, setIdentitySent] = useState(false);

    const email = useSelector(state => state.reader?.user?.email);
    const location = useLocation();

    useEffect(() => {
        window._hsq = window._hsq || [];

        if (email && !identitySent) {
            // send to the GTM datalayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                email
            });

            const identityPayload = { 
                email,
                last_login: Math.floor(Date.now()) 
            };

            window._hsq.push(['identify', identityPayload]);
            setIdentitySent(true);
        }
        
        if (lastLocation && location !== lastLocation) {
            window._hsq.push(['trackPageView']);
        }

        setLastLocation(location);
    }, [email, identitySent, lastLocation, location, setLastLocation]);
};

export default useHubspotTracking;
