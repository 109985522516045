import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reader_id: null,
    user: null,
    initialized: false,
    is_authenticating: false,
    error: false
};

if (window.reader) {
    initialState.reader_id = window.reader.reader_id;
    initialState.user = window.reader.user;
    initialState.initialized = true;
}

export const readerSlice = createSlice({
    name: 'reader',
    initialState,
    reducers: {
        initReader: (state, action) => {
            state.reader_id = action.payload.reader_id;
            state.user = action.payload.user;
            state.initialized = true;
            state.is_authenticating = false;
        },
        setAuthenticating: (state, action) => {
            state.is_authenticating = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    }
});

export const { initReader, setAuthenticating, setError } = readerSlice.actions;

export default readerSlice.reducer;
