import React from 'react';

const PageLoader = () => (
    <div className='min-h-[calc(100vh-484px)] sm:min-h-[calc(100vh-432px)] min-w-screen py-6 flex items-center justify-center'>
        <div className='flex space-x-2 animate-pulse'>
            <div className='w-3 h-3 bg-black rounded-full'></div>
            <div className='w-3 h-3 bg-black rounded-full'></div>
            <div className='w-3 h-3 bg-black rounded-full'></div>
        </div>
    </div>
);

export default PageLoader;
