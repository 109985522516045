import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticating, setError, initReader } from '../redux/readerSlice';

const useGetReader = () => {
    const dispatch = useDispatch();
    const reader_id = useSelector(state => state.reader.reader_id);
    const is_authenticating = useSelector(
        state => state.reader.is_authenticating
    );
    const error = useSelector(state => state.reader.error);

    const _initReader = useCallback(() => {
        if (reader_id || is_authenticating || error) return;

        dispatch(setAuthenticating(true));
        fetch(`${process.env.REACT_APP_API_URL}/init-reader`, {
            credentials: 'include'
        })
            .then(r => {
                if (!r.ok) {
                    throw new Error();
                }
                return r;
            })
            .then(r =>
                r.json().then(reader_info => {
                    dispatch(initReader(reader_info));
                })
            )
            .catch(() => {
                dispatch(setError(true));
            });
    }, [dispatch, reader_id, error, is_authenticating]);
    return { initReader: _initReader };
};

export default useGetReader;
