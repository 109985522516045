import React from 'react';
import { addCommasInNumbers } from '../../../../../../utilities';
import StatBadgePlaceholder from './StatBadgePlaceholder';

const stylesCreator = ({
    bgColor = '#2886d0',
    borderColor = '#000',
    sectorColor = '#000'
}) => ({
    wrapper: {
        width: '350px',
        maxWidth: '100%',
        height: '130px',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'flex-start'
    },
    logoBox: {
        backgroundColor: bgColor,
        width: '117px',
        height: '130px',
        padding: '10px'
    },
    logo: {
        height: '100%'
    },
    indexLevelBox: {
        border: `2px solid ${borderColor}`,
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column'
    },
    indexLevelWrap: {
        flexGrow: '1'
    },
    indexLevel: {
        fontSize: '40px',
        padding: '0 15px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    asOf: {
        fontSize: '12px',
        color: '#888'
    },
    sector: {
        backgroundColor: sectorColor,
        color: '#fff',
        textAlign: 'center',
        padding: '5px 10px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '14px',
        borderTop: `2px solid ${borderColor}`
    }
});

const StatBadge = ({ logo, number, label, color, obscured }) => {
    const styles = stylesCreator({ bgColor: color });

    return (
        <div
            style={styles.wrapper}
            className='mt-4 sm:mt-0 landscape:wide:mt-4 sm:mb-[30px] landscape:wide:mb-0'
        >
            {logo && (
                <div style={styles.logoBox}>
                    <img style={styles.logo} src={logo} alt='stat-badge-logo' />
                </div>
            )}
            <div style={styles.indexLevelBox}>
                <div style={styles.indexLevelWrap} className='relative'>
                    {obscured && <StatBadgePlaceholder />}
                    <div
                        style={styles.indexLevel}
                        className={obscured && 'blur'}
                    >
                        {obscured
                            ? addCommasInNumbers(
                                  Math.floor(
                                      Math.random() * (1400 - 800 + 1) + 800
                                  )
                              )
                            : number}
                    </div>
                </div>
                {label && <div style={styles.sector}>{label}</div>}
            </div>
        </div>
    );
};

export default StatBadge;
